import { Translation } from './types';
export default {
  Header: {
    subtitle: 'Uma ótima maneira de reclamar se divertindo',
    logout: 'Logout',
    leave: 'Sair',
    summaryMode: 'Modo Sumário',
  },
  LanguagePicker: {
    header: 'Escolha uma língua',
  },
  Main: {
    hint:
      'Você pode convidar outras pessoas para esta seção compartilhando a URL',
  },
  Home: {
    welcome: undefined,
  },
  PreviousGame: {
    createdBy: undefined,
    posts: undefined,
    participants: undefined,
    votes: undefined,
    actions: undefined,
  },
  Column: {
    createGroupTooltip: undefined,
  },
  Group: {
    emptyGroupTitle: '',
    emptyGroupContent: '',
  },
  Post: {
    openExtra: undefined,
    closeExtra: undefined,
    vote: 'vote',
    votes: 'votos',
    deleteButton: 'Remover',
    setActionButton: undefined,
    setGiphyButton: undefined,
    noContent: '(This post has no content)',
    by: undefined,
    upVote: undefined,
    downVote: undefined,
    voteRemainingMultiple: undefined,
    voteRemainingOne: undefined,
    voteRemainingNone: undefined,
    toggleGiphyButton: undefined,
  },
  Customize: {
    title: undefined,
    votingCategory: undefined,
    votingCategorySub: undefined,
    postCategory: undefined,
    postCategorySub: undefined,
    customTemplateCategory: undefined,
    customTemplateCategorySub: undefined,
    startButton: undefined,
    maxUpVotes: undefined,
    maxUpVotesHelp: undefined,
    maxDownVotes: undefined,
    maxDownVotesHelp: undefined,
    allowSelfVoting: undefined,
    allowSelfVotingHelp: undefined,
    allowMultipleVotes: undefined,
    allowMultipleVotesHelp: undefined,
    allowActions: undefined,
    allowActionsHelp: undefined,
    allowAuthorVisible: undefined,
    allowAuthorVisibleHelp: undefined,
    allowGiphy: undefined,
    allowGiphyHelp: undefined,
    allowGrouping: undefined,
    allowGroupingHelp: undefined,
    allowReordering: undefined,
    allowReorderingHelp: undefined,
    template: undefined,
    templateHelp: undefined,
    numberOfColumns: undefined,
    numberOfColumnsHelp: undefined,
    makeDefaultTemplate: undefined,
  },
  PostBoard: {
    customQuestion: undefined,
    notWellQuestion: 'O que poderia ser melhorado?',
    wellQuestion: 'O que deu certo?',
    ideasQuestion: 'Compartilhar uma ideia brilhante?',
    startQuestion: undefined,
    stopQuestion: undefined,
    continueQuestion: undefined,
    likedQuestion: undefined,
    lackedQuestion: undefined,
    learnedQuestion: undefined,
    longedForQuestion: undefined,
    anchorQuestion: undefined,
    boatQuestion: undefined,
    islandQuestion: undefined,
    windQuestion: undefined,
    rockQuestion: undefined,
    disconnected: undefined,
    reconnect: undefined,
    notLoggedIn: undefined,
  },
  GameMenu: {
    board: undefined,
    summary: undefined,
  },
  Template: {
    default: undefined,
    wellNotWell: undefined,
    startStopContinue: undefined,
    fourLs: undefined,
    sailboat: undefined,
  },
  Clients: {
    header: 'Participando conosco agora:',
  },
  Join: {
    welcome: 'Bem-vindo ao Retrospected',
    standardTab: {
      header: 'Crie uma seção',
      text: 'Clique abaixo e inicie a restropectiva:',
      button: 'Crie uma nova seção',
      customizeButton: undefined,
    },
    optionsTab: {
      header: 'Avançado',
      input: 'Insira um nome para sua seção',
      button: 'Crie uma seção customizada',
    },
    previousTab: {
      header: 'Previous sessions',
      rejoinButton: 'Rejoin',
    },
  },
  Login: {
    namePlaceholder: 'Quem é você? Insira seu nome aqui',
    buttonLabel: 'Vamos começar',
    header: 'Login',
    socialMediaAuthHeader: undefined,
    socialMediaAuthDescription: undefined,
    anonymousAuthHeader: undefined,
    anonymousAuthDescription: undefined,
    authenticatingWith: undefined,
    or: undefined,
  },
  SummaryBoard: {
    noPosts: 'There are no posts to display',
    copyAsMarkdown: undefined,
    copyAsRichText: undefined,
    copySuccessful: undefined,
  },
  SessionName: {
    defaultSessionName: 'My Retrospective',
  },
  Invite: {
    inviteButton: 'Invite',
    dialog: {
      title: 'Invite people to your retrospective',
      text:
        'To invite people to your retrospected session, simply send them ' +
        'the following URL',
      copyButton: 'Copy URL to Clipboard',
    },
  },
  Generic: {
    ok: 'OK',
    cancel: 'Cancelar',
  },
  Actions: {
    tooltip: 'Crie uma ação no verso deste item',
    label: 'Abra o painel de ação',
    summaryTitle: 'Suas ações',
    title: 'Açao',
  },
} as Translation;
