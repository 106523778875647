import { Translation } from './types';
export default {
  Header: {
    subtitle: '驾驭敏捷方式的好方法',
    logout: '注销',
    leave: '离开',
    summaryMode: '主旨模式',
  },
  LanguagePicker: {
    header: '切换语言',
  },
  Main: {
    hint: '复制并分享网址，即可让别人加入此议程',
  },
  Home: {
    welcome: undefined,
  },
  PreviousGame: {
    createdBy: undefined,
    posts: undefined,
    participants: undefined,
    votes: undefined,
    actions: undefined,
  },
  Column: {
    createGroupTooltip: undefined,
  },
  Group: {
    emptyGroupTitle: '',
    emptyGroupContent: '',
  },
  Post: {
    openExtra: undefined,
    closeExtra: undefined,
    vote: '投票',
    votes: '表决',
    deleteButton: '删除',
    setActionButton: undefined,
    setGiphyButton: undefined,
    noContent: '(目前无任何内容)',
    by: undefined,
    upVote: undefined,
    downVote: undefined,
    voteRemainingMultiple: undefined,
    voteRemainingOne: undefined,
    voteRemainingNone: undefined,
    toggleGiphyButton: undefined,
  },
  Customize: {
    title: undefined,
    votingCategory: undefined,
    votingCategorySub: undefined,
    postCategory: undefined,
    postCategorySub: undefined,
    customTemplateCategory: undefined,
    customTemplateCategorySub: undefined,
    startButton: undefined,
    maxUpVotes: undefined,
    maxUpVotesHelp: undefined,
    maxDownVotes: undefined,
    maxDownVotesHelp: undefined,
    allowSelfVoting: undefined,
    allowSelfVotingHelp: undefined,
    allowMultipleVotes: undefined,
    allowMultipleVotesHelp: undefined,
    allowActions: undefined,
    allowActionsHelp: undefined,
    allowAuthorVisible: undefined,
    allowAuthorVisibleHelp: undefined,
    allowGiphy: undefined,
    allowGiphyHelp: undefined,
    allowGrouping: undefined,
    allowGroupingHelp: undefined,
    allowReordering: undefined,
    allowReorderingHelp: undefined,
    template: undefined,
    templateHelp: undefined,
    numberOfColumns: undefined,
    numberOfColumnsHelp: undefined,
    makeDefaultTemplate: undefined,
  },
  PostBoard: {
    customQuestion: undefined,
    notWellQuestion: '哪些已经改善?',
    wellQuestion: '哪些做的很好?',
    ideasQuestion: '分享优秀的点子?',
    startQuestion: undefined,
    stopQuestion: undefined,
    continueQuestion: undefined,
    likedQuestion: undefined,
    lackedQuestion: undefined,
    learnedQuestion: undefined,
    longedForQuestion: undefined,
    anchorQuestion: undefined,
    boatQuestion: undefined,
    islandQuestion: undefined,
    windQuestion: undefined,
    rockQuestion: undefined,
    disconnected: undefined,
    reconnect: undefined,
    notLoggedIn: undefined,
  },
  GameMenu: {
    board: undefined,
    summary: undefined,
  },
  Template: {
    default: undefined,
    wellNotWell: undefined,
    startStopContinue: undefined,
    fourLs: undefined,
    sailboat: undefined,
  },
  Clients: {
    header: '已加入我们的:',
  },
  Join: {
    welcome: '欢迎 Retrospected',
    standardTab: {
      header: '创建',
      text: '点击以下开始进行回顾:',
      button: '创建新的议程',
      customizeButton: undefined,
    },
    optionsTab: {
      header: '高级设定',
      input: '名称',
      button: '创建议程',
    },
    previousTab: {
      header: '之前的议程',
      rejoinButton: '再次加入',
    },
  },
  Login: {
    namePlaceholder: '您是谁? 在此输入您的名称',
    buttonLabel: '开始!',
    header: 'Login',
    socialMediaAuthHeader: undefined,
    socialMediaAuthDescription: undefined,
    anonymousAuthHeader: undefined,
    anonymousAuthDescription: undefined,
    authenticatingWith: undefined,
    or: undefined,
  },
  SummaryBoard: {
    noPosts: '目前无任何内容',
    copyAsMarkdown: undefined,
    copyAsRichText: undefined,
    copySuccessful: undefined,
  },
  SessionName: {
    defaultSessionName: '我的回顾',
  },
  Invite: {
    inviteButton: '邀请',
    dialog: {
      title: '邀请别人来到您的议程',
      text: '邀请别人来到您的议程，只要分享以下的网址给他们',
      copyButton: '复制网址到剪贴簿',
    },
  },
  Generic: {
    ok: 'OK',
    cancel: '取消',
  },
  Actions: {
    tooltip: '在此项目的背面创建一个操作',
    label: '打开“操作”面板',
    summaryTitle: '你的行动',
    title: '行动',
  },
} as Translation;
