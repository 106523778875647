import { Translation } from './types';
export default {
  Header: {
    subtitle: 'Een goede manier om in een gestructureerde manier te tieren',
    logout: 'Afmelden',
    leave: 'Verlaten',
    summaryMode: 'Samenvatting',
  },
  LanguagePicker: {
    header: 'Kies een taal',
  },
  Main: {
    hint:
      'Je kan anderen uitnodigen voor deze sessie door de URL te kopiëren en te plakken',
  },
  Home: {
    welcome: undefined,
  },
  PreviousGame: {
    createdBy: undefined,
    posts: undefined,
    participants: undefined,
    votes: undefined,
    actions: undefined,
  },
  Column: {
    createGroupTooltip: undefined,
  },
  Group: {
    emptyGroupTitle: '',
    emptyGroupContent: '',
  },
  Post: {
    openExtra: undefined,
    closeExtra: undefined,
    vote: 'Stem',
    votes: 'Stemmen',
    deleteButton: 'Verwijderen',
    setActionButton: undefined,
    setGiphyButton: undefined,
    noContent: '(Dit bericht heeft geen inhoud)',
    by: undefined,
    upVote: undefined,
    downVote: undefined,
    voteRemainingMultiple: undefined,
    voteRemainingOne: undefined,
    voteRemainingNone: undefined,
    toggleGiphyButton: undefined,
  },
  Customize: {
    title: undefined,
    votingCategory: undefined,
    votingCategorySub: undefined,
    postCategory: undefined,
    postCategorySub: undefined,
    customTemplateCategory: undefined,
    customTemplateCategorySub: undefined,
    startButton: undefined,
    maxUpVotes: undefined,
    maxUpVotesHelp: undefined,
    maxDownVotes: undefined,
    maxDownVotesHelp: undefined,
    allowSelfVoting: undefined,
    allowSelfVotingHelp: undefined,
    allowMultipleVotes: undefined,
    allowMultipleVotesHelp: undefined,
    allowActions: undefined,
    allowActionsHelp: undefined,
    allowAuthorVisible: undefined,
    allowAuthorVisibleHelp: undefined,
    allowGiphy: undefined,
    allowGiphyHelp: undefined,
    allowGrouping: undefined,
    allowGroupingHelp: undefined,
    allowReordering: undefined,
    allowReorderingHelp: undefined,
    template: undefined,
    templateHelp: undefined,
    numberOfColumns: undefined,
    numberOfColumnsHelp: undefined,
    makeDefaultTemplate: undefined,
  },
  PostBoard: {
    customQuestion: undefined,
    notWellQuestion: 'Wat kan er beter?',
    wellQuestion: 'Wat ging goed?',
    ideasQuestion: 'Een geweldig idee om te delen?',
    startQuestion: undefined,
    stopQuestion: undefined,
    continueQuestion: undefined,
    likedQuestion: undefined,
    lackedQuestion: undefined,
    learnedQuestion: undefined,
    longedForQuestion: undefined,
    anchorQuestion: undefined,
    boatQuestion: undefined,
    islandQuestion: undefined,
    windQuestion: undefined,
    rockQuestion: undefined,
    disconnected: undefined,
    reconnect: undefined,
    notLoggedIn: undefined,
  },
  GameMenu: {
    board: undefined,
    summary: undefined,
  },
  Template: {
    default: undefined,
    wellNotWell: undefined,
    startStopContinue: undefined,
    fourLs: undefined,
    sailboat: undefined,
  },
  Clients: {
    header: 'Onze lieftallige deelnemers:',
  },
  Join: {
    welcome: 'Welkom bij Retrospected',
    standardTab: {
      header: 'Maak een sessie',
      text: 'Klik hieronder en begin terug te denken:',
      button: 'Start een nieuwe sessie',
      customizeButton: undefined,
    },
    optionsTab: {
      header: 'Geavanceerd',
      input: 'Geef een naam voor de sessie',
      button: 'Maak een aangepaste sessie',
    },
    previousTab: {
      header: 'Vorige sessies',
      rejoinButton: 'Opnieuw deelnemen',
    },
  },
  Login: {
    namePlaceholder: 'Wie ben jij precies? Typ je naam hier',
    buttonLabel: 'Laten we beginnen',
    header: 'Login',
    socialMediaAuthHeader: undefined,
    socialMediaAuthDescription: undefined,
    anonymousAuthHeader: undefined,
    anonymousAuthDescription: undefined,
    authenticatingWith: undefined,
    or: undefined,
  },
  SummaryBoard: {
    noPosts: 'Er zijn geen punten om te tonen',
    copyAsMarkdown: undefined,
    copyAsRichText: undefined,
    copySuccessful: undefined,
  },
  SessionName: {
    defaultSessionName: 'Mijn Retrospective',
  },
  Invite: {
    inviteButton: 'Uitnodigen',
    dialog: {
      title: 'Nodig mensen uit voor jou retrospective',
      text:
        'Stuur de mensen die je wil uitnodigen voor jou retrospected ' +
        'sessie gewoon de volgende url',
      copyButton: 'Kopieer URL naar klembord',
    },
  },
  Generic: {
    ok: 'OK',
    cancel: 'annuleren',
  },
  Actions: {
    tooltip: 'Maak een actie op de achterkant van dit item',
    label: 'Open het actiepaneel',
    summaryTitle: 'Je acties',
    title: 'Actie',
  },
} as Translation;
